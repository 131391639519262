body {
  background-color: #b7dba5; /* Orange color */
}

.App {
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}

.filterContainer {
  font-size: 2vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 1vh
}

.filters{
  flex-grow: 1;
  flex-shrink: 1;
}

.logo {
  background-color: rgba(0, 0, 0, 0.0); /* Example background color */
  background-image: url("logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-left: 25%;
  padding-bottom: 50%;
  width: 50%;
}

.searchBar {
  width: 100%;
  padding: 0.5vw;
  border-radius: 1vw;
  border: solid 0.5vh;
  border-color: #555b78;
  background-color: #ffffff; /* Orange color */
  color: #000000;
  text-align: center;
  box-sizing: border-box;
  font-size: inherit;
}

.filter {
  text-align: center;
  box-sizing: border-box;
  font-size: inherit;
}

.filter select {
  width: 100%;
  padding: 0.5vw;
  border-radius: 1vw;
  border: solid 0.5vh;
  border-color: #555b78;
  background-color: #ffffff; /* Orange color */
  color: #000000;
  text-align: center;
  box-sizing: border-box;
  font-size: inherit;
}


.grid-container {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  flex-grow: 1;
  flex-shrink: 1;
}

.grid-item-container {
  position: relative;
  background-color: black; /* Black boxes */
  border-radius: 5%;
  width: 25%;
  height: 0px;
  padding-bottom: 25%;
}

.grid-item {
  position: absolute;
  border-radius: 5%;
  top: 1%;
  right: 0%;
  bottom: 1%;
  left: 1%;
  width: 98%;
  background-color: rgba(0, 0, 0, 0.5); /* Example background color */
  background-image: url("https://cms-assets.tutsplus.com/cdn-cgi/image/width=360/uploads/users/1631/posts/32707/item-list-card/TechLogos04_copy.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}



@media (orientation:portrait) {
  .App {
    flex-direction: column;
  }

  .grid-item-container {
    width: 50%;
    padding-bottom: 50%;
  }

  .logo {
    margin-left: 45%;
    padding-bottom: 10%;
    width: 10%;
  }
}